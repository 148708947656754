<template>
	<div class="w-full">
		<div v-if="alertShow">
			<div
				class="bg-yellow-50 border-l-4 border-yellow-600 p-4 rounded-md"
			>
				<div class="relative">
					<div>
						<img
							src="@/assets/images/logo/logo.svg"
							alt="luca-logo"
							height="24"
							width="35"
						/>
					</div>
					<div class="mt-3 text-neutral-600 text-sm">
						Bạn hãy ấn nút “DẠY MT” để chuyển mục tiêu vào Kế hoạch
						dự kiến
					</div>
					<div
						class="top-1 right-2 absolute cursor-pointer"
						@click="alertShow = false"
					>
						<svg
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
								fill="#E5A42B"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
		<div class="mt-10">
			<div class="p-6 bg-white shadow-sm rounded-2xl mb-10">
				<div>
					<div class="flex items-center justify-between mb-8">
						<div class="font-semibold text-neutral-600">
							Thống kê Ngân hàng mục tiêu
						</div>
						<button
							type="button"
							class="
								button-p
								text-center
								bg-none
								shadow-none
								flex
								items-center
								justify-center
								btn--text
								w-fit
							"
							v-if="false"
						>
							<svg
								class="mr-2"
								width="16"
								height="16"
								viewBox="0 0 16 16"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M12.4702 6.65087L13.7102 5.41084C13.8461 5.27498 13.914 5.20705 13.9667 5.14509C14.6009 4.39841 14.6009 3.30223 13.9667 2.55555C13.914 2.49359 13.8461 2.42566 13.7102 2.2898C13.5744 2.15394 13.5064 2.08601 13.4445 2.03338C12.6978 1.39915 11.6016 1.39915 10.855 2.03338C10.793 2.086 10.7251 2.15391 10.5893 2.2897L10.5892 2.28978L10.5892 2.2898L9.33099 3.54801C10.0747 4.84221 11.1583 5.91748 12.4702 6.65087ZM7.87633 5.00267L2.86273 10.0163C2.43767 10.4413 2.22514 10.6539 2.0854 10.915C1.94567 11.1761 1.88672 11.4708 1.76883 12.0602L1.37318 14.0385C1.30666 14.3711 1.27339 14.5374 1.368 14.632C1.46261 14.7266 1.62892 14.6934 1.96153 14.6269L3.9398 14.2312L3.93982 14.2312L3.93984 14.2312C4.52927 14.1133 4.82399 14.0544 5.08508 13.9146C5.34617 13.7749 5.55869 13.5624 5.98373 13.1373L5.98376 13.1373L5.98377 13.1373L11.0118 8.10931C9.75111 7.3111 8.68202 6.24922 7.87633 5.00267Z"
									fill="currentColor"
								/>
							</svg>
							Chỉnh sửa
						</button>
					</div>
				</div>
				<div class="flex flex-nowrap w-full md-down:flex-col">
					<div
						class="
							p-6
							border
							rounded-l-lg
							border-neutral-200
							w-full
							md-down:rounded-t md-down:rounded-b-none
							md:border-r-0
							md-down:border-b-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								src="@/assets/images/icons/abc.png"
								alt=""
								width="32"
								height="32"
								class="mr-2"
								style="height: 32px"
							/>
							{{ skill_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số lĩnh vực
							</span>
						</div>
					</div>
					<div
						class="
							p-6
							border
							rounded-r-lg
							border-neutral-200
							w-full
							md-down:rounded-b md-down:rounded-t-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								src="@/assets/images/icons/light.png"
								alt=""
								width="32"
								height="32"
								class="mr-2"
								style="height: 32px"
							/>
							{{ goal_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số mục tiêu</span
							>
						</div>
					</div>
				</div>
			</div>
			<div v-if="goals && goals.length > 0">
				<div>
					<div
						class="mb-10"
						v-for="(goal, index) in goals"
						:key="index"
					>
						<div
							class="font-semibold text-lg text-neutral-600 mb-4"
						>
							{{ index + 1 }}. {{ goal.skill.name }}
						</div>
						<div class="shadow-sm sm:rounded-2xl overflow-hidden">
							<div
								class="
									__record__table
									overflow-x-auto
									scroll-bar
								"
							>
								<div
									class="
										overflow-hidden
										sm:rounded-t-2xl
										w-fit
										min-w-full
									"
								>
									<table
										class="
											min-w-full
											divide-y
											text-neutral-200
										"
									>
										<thead class="bg-neutral-100 h-16">
											<tr>
												<th
													scope="col"
													class="
														px-6
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														w-16
														whitespace-nowrap
													"
												>
													STT
												</th>
												<th
													scope="col"
													class="
														px-6
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														whitespace-nowrap
													"
													style="min-width: 160px"
												>
													Nội dung
												</th>
												<th class="w-max"></th>
											</tr>
										</thead>
										<tbody>
											<template
												v-for="(g, i) in goal.goals"
												:key="g.goal_id || g.id"
											>
												<tr
													:class="
														i % 2 === 0
															? 'bg-white'
															: 'bg-neutral-50'
													"
													class="text-neutral-700"
												>
													<td
														class="
															px-6
															py-4
															whitespace-nowrap
															text-neutral-700
														"
													>
														<div class="ml-2">
															{{ i + 1 }}
														</div>
													</td>
													<td
														class="
															px-6
															py-4
															text-neutral-700
															w-full
														"
													>
														<div
															class="
																flex
																items-center
																justify-between
															"
														>
															<router-link
																:to="`/goal/${id}/${
																	g.goal_id ||
																	g.id
																}`"
															>
																<div
																	class="
																		hover:text-yellow-700
																		hover:underline
																	"
																	v-html="
																		g.goal_name ||
																		g.name
																	"
																></div>
															</router-link>
															<TooltipGoalDetail
																:goal-id="
																	g.goal_id ||
																	g.id
																"
																:student-id="id"
															/>
														</div>
														<div
															class="
																flex
																gap-2
																flex-wrap
															"
															style="
																max-width: 360px;
															"
															v-if="
																(g?.assistance_levels &&
																	org.enable_goal_assistance_level) ||
																g?.raw_source
															"
														>
															<div
																class="
																	mt-1
																	py-0.5
																	px-2.5
																	rounded-full
																	bg-neutral-200
																	text-neutral-700
																	h-fit
																	w-fit
																	whitespace-nowrap
																	font-semibold
																	shadow-input
																"
																style="
																	font-size: 10px;
																"
																v-if="
																	g?.raw_source
																"
															>
																{{
																	g?.display_source
																}}
															</div>
															<template
																v-if="
																	g?.assistance_levels &&
																	org.enable_goal_assistance_level
																"
															>
																<div
																	v-for="ass in g?.assistance_levels"
																	:key="ass"
																	class="
																		flex
																		gap-2
																	"
																>
																	<div
																		class="
																			mt-1
																			py-0.5
																			px-2.5
																			rounded-full
																			bg-pink-100
																			whitespace-nowrap
																			text-neutral-700
																			h-fit
																			w-fit
																			font-semibold
																			shadow-input
																		"
																		style="
																			font-size: 10px;
																		"
																	>
																		{{
																			ass
																		}}
																	</div>
																</div>
															</template>
															<div
																class="
																	mt-1
																	py-0.5
																	px-2.5
																	rounded-full
																	bg-yellow-tag
																	text-neutral-700
																	h-fit
																	w-fit
																	font-semibold
																	shadow-input
																	whitespace-nowrap
																"
																style="
																	font-size: 10px;
																"
																v-if="g?.age"
															>
																{{ g?.age }}
															</div>
														</div>
														<div
															v-if="
																g.parent
																	?.sub_goal_count >
																0
															"
															class="
																goal-block
																flex
																gap-3
																mt-2
															"
														>
															<img
																class="
																	w-3
																	h-3
																	mt-1
																"
																src="@/assets/images/icons/goal-arrow.png"
																alt=""
															/>
															<div>
																<div
																	class="
																		text-neutral-700
																	"
																>
																	{{
																		g.parent
																			?.name
																	}}
																</div>
																<div
																	class="
																		text-neutral-400
																		text-xs
																		font-semibold
																	"
																>
																	{{
																		g.parent
																			?.sub_goal_count
																	}}
																	MỤC TIÊU
																</div>
															</div>
														</div>
													</td>
													<td class="py-4 pr-6">
														<button
															v-if="
																goal_added.includes(
																	g.goal_id
																) ||
																g.state !==
																	'library'
															"
															type="button"
															class="
																button-p
																btn--text
																whitespace-nowrap
																text-green-600
															"
														>
															<svg
																class="mr-4"
																width="20"
																height="20"
																viewBox="0 0 20 20"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M11.212 6.21209C11.5782 5.84597 12.1717 5.84597 12.5379 6.21209C12.8995 6.57371 12.9039 7.15727 12.5512 7.52434L7.56092 13.7622C7.55371 13.7712 7.54602 13.7798 7.53787 13.7879C7.17175 14.154 6.57816 14.154 6.21204 13.7879L2.90398 10.4799C2.53787 10.1137 2.53787 9.52015 2.90398 9.15403C3.2701 8.78791 3.86369 8.78791 4.22981 9.15403L6.84671 11.7709L11.1872 6.24013C11.1949 6.23029 11.2032 6.22093 11.212 6.21209Z"
																	fill="#2AA797"
																/>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M10.0615 12.6374L11.212 13.7879C11.5782 14.154 12.1718 14.154 12.5379 13.7879C12.546 13.7798 12.5537 13.7712 12.5609 13.7622L17.5512 7.52434C17.9039 7.15727 17.8995 6.57371 17.5379 6.21209C17.1717 5.84597 16.5782 5.84597 16.212 6.21209C16.2032 6.22093 16.1949 6.23029 16.1872 6.24013L11.8467 11.7709L11.24 11.1642L10.0615 12.6374Z"
																	fill="#2AA797"
																/>
															</svg>
															<div
																class="
																	text-green-600
																"
															>
																THÊM VÀO KH
															</div>
														</button>
														<button
															v-else
															type="button"
															class="
																button-p
																btn--text
																whitespace-nowrap
															"
															@click="
																doGoal(
																	g.goal_id ||
																		g.id
																)
															"
														>
															Dạy MT
														</button>
													</td>
												</tr>
											</template>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-else
				class="w-full h-full flex items-center justify-center flex-col"
			>
				<img
					src="@/assets/images/illustrations/not_found.png"
					alt=""
					height="250"
					width="250"
				/>
				<div class="text-neutral-600 text-center text-lg">
					Chưa có mục tiêu trong Ngân hàng mục tiêu
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import {
	computed,
	ref,
	onBeforeUnmount,
	watch,
	defineEmits,
	onMounted,
} from 'vue';
import { useStudent } from '@/features/student/useStudent';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { $api } from '@/services';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';

const { goalLibByStudent } = useStudent();
const route = useRoute();
const store = useStore();
const goals = ref([]);
const skill_count = ref(0);
const goal_count = ref(0);
const alertShow = ref(true);
const emit = defineEmits(['updated']);

const id = computed(() => (route.params?.id ? route.params?.id + '' : ''));
const plan_id = computed(() =>
	route.params?.plan_id ? String(route.params?.plan_id) : ''
);

async function fetchGoals() {
	const res = await goalLibByStudent(id.value);
	if (res?.data) {
		goals.value = res?.data?.skills ?? [];
		if (res?.stats) {
			skill_count.value = res?.stats?.skills_count || 0;
			goal_count.value = res?.stats?.goals_count || 0;
		}
	}
}

async function doGoal(goal_id) {
	try {
		await $api.goal.doGoalByLib(id.value, plan_id.value, {
			goal_id,
		});
		await store.dispatch('addGoal', goal_id);
		emit('updated');
	} catch (e) {
		await store.dispatch('setAlertMessage', {
			message:
				e?.response?.data?.errors?.join('<br/>') ||
				'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	}
}

onMounted(() => {
	fetchGoals();
});

const reload = computed(() => store.getters.reload);
const goal_added = computed(() => store.getters.goalAdded);
const org = computed(() => store.state.profile.current_org);

watch(reload, () => {
	fetchGoals();
});

onBeforeUnmount(() => {
	store.dispatch('clearAdded');
});
</script>

<style scoped>
.goal-block {
	background: #eef3f8;
	padding: 12px;
	border-radius: 8px;
	border: 1px solid #bacde4;
}
</style>
