<template>
	<div class="shadow-sm sm:rounded-2xl overflow-hidden">
		<div class="__record__table overflow-x-auto scroll-bar">
			<div class="overflow-hidden sm:rounded-t-2xl w-fit min-w-full">
				<table class="min-w-full divide-y text-neutral-200">
					<thead class="bg-neutral-100 h-16">
						<tr>
							<th
								scope="col"
								class="
									px-6
									py-3
									text-left text-xs
									font-semibold
									text-neutral-600
									uppercase
									tracking-wider
									w-8
									whitespace-nowrap
								"
							></th>
							<th
								class="
									px-6
									py-3
									text-left text-xs
									font-semibold
									text-neutral-600
									uppercase
									tracking-wider
									w-16
									whitespace-nowrap
								"
								scope="col"
							>
								STT
							</th>
							<th
								class="
									px-6
									py-3
									text-left text-xs
									font-semibold
									text-neutral-600
									uppercase
									tracking-wider
									whitespace-nowrap
								"
								scope="col"
							>
								Lĩnh vực
							</th>
							<th
								class="
									px-6
									py-3
									text-left text-xs
									font-semibold
									text-neutral-600
									uppercase
									tracking-wider
									whitespace-nowrap
								"
								scope="col"
								style="min-width: 160px"
							>
								Nội dung
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-for="(group, i) in data" :key="group.id">
							<template
								v-for="g in group.goals"
								:key="g.goal_id || g.id"
							>
								<tr
									:class="
										i % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="text-neutral-700 cursor-pointer"
									@click.stop="
										$router.push(
											`/goal/${studentId}/${
												g.goal_id || g.id
											}`
										)
									"
								>
									<td
										class="px-6 py-4 w-8"
										@click.stop="openSubGoal(g)"
									>
										<div
											v-if="g.sub_goal_count > 0"
											class="w-4"
										>
											<img
												class="w-full"
												v-if="g.open"
												src="@/assets/images/icons/arrow-up.png"
												alt=""
											/>
											<img
												class="w-full"
												v-else
												src="@/assets/images/icons/arrow-down.png"
												alt=""
											/>
										</div>
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div class="ml-2">
											{{ i + 1 }}
										</div>
									</td>
									<td class="px-6 py-4 text-neutral-700">
										{{ g?.skill_name }}
									</td>
									<td class="px-6 py-4 text-neutral-700">
										<div class="flex items-center">
											<div
												class="
													mr-2
													hover:text-yellow-700
													hover:underline
												"
												v-html="g.goal_name || g.name"
											></div>
											<TooltipGoalDetail
												v-if="g.sub_goal_count == 0"
												:goal-id="g.goal_id || g.id"
												:is-group="isGroup"
												:student-id="
													studentId.toString()
												"
											/>
										</div>
										<div
											class="flex gap-2 flex-wrap"
											style="max-width: 360px"
										>
											<div
												v-if="g?.source"
												class="
													mt-1
													py-0.5
													px-2.5
													rounded-full
													bg-neutral-200
													text-neutral-700
													h-fit
													w-fit
													font-semibold
													shadow-input
													whitespace-nowrap
												"
												style="font-size: 10px"
											>
												{{ g?.source }}
											</div>
											<div
												v-else
												class="
													mt-1
													py-0.5
													px-2.5
													rounded-full
													bg-neutral-200
													text-neutral-700
													h-fit
													w-fit
													font-semibold
													shadow-input
													whitespace-nowrap
												"
												style="font-size: 10px"
											>
												{{
													$filters.sourceMap(
														g?.state,
														g.editable
													)
												}}
											</div>
											<template
												v-if="
													g?.assistance_levels &&
													org.enable_goal_assistance_level
												"
											>
												<div
													v-for="ass in g?.assistance_levels"
													:key="ass"
													class="flex gap-2"
												>
													<div
														class="
															mt-1
															py-0.5
															px-2.5
															rounded-full
															bg-pink-100
															whitespace-nowrap
															text-neutral-700
															h-fit
															w-fit
															font-semibold
															shadow-input
														"
														style="font-size: 10px"
													>
														{{ ass }}
													</div>
												</div>
											</template>
											<div
												class="
													mt-1
													py-0.5
													px-2.5
													rounded-full
													bg-yellow-tag
													text-neutral-700
													h-fit
													w-fit
													font-semibold
													shadow-input
													whitespace-nowrap
												"
												style="font-size: 10px"
												v-if="g?.age"
											>
												{{ g?.age }}
											</div>
										</div>
										<div
											class="flex items-center gap-2 mt-2"
										>
											<div v-if="g?.has_media">
												<img
													alt=""
													src="@/assets/images/icons/has_media.png"
												/>
											</div>
											<div
												v-if="
													g.number_of_assessment > 0
												"
												class="
													text-neutral-400 text-xs
													font-semibold
												"
											>
												ĐÃ HỌC
												{{ g.number_of_assessment }}
												NGÀY
											</div>
											<div v-if="g?.completed_at">
												<img
													alt=""
													src="@/assets/images/icons/has_taught.png"
												/>
											</div>
										</div>
										<div
											class="flex items-center gap-2 mt-2"
										>
											<div
												class="
													text-neutral-400 text-xs
													font-semibold
												"
											>
												KẾT QUẢ
											</div>
											<div
												class="
													progress-bar
													overflow-hidden
												"
											>
												<div
													:style="{
														background:
															$filters.tagResult(
																g.complete_percent
															)?.background,
														width:
															g.complete_percent +
															'%',
													}"
													class="h-full"
												></div>
											</div>
											<div
												class="
													text-neutral-400 text-xs
													font-semibold
												"
											>
												{{ g.complete_percent }}%
											</div>
										</div>
									</td>
								</tr>
								<tr
									class="text-neutral-700 cursor-pointer"
									:class="
										i % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									v-if="g.open"
								>
									<td colspan="4" class="pl-16">
										<child-goal
											:data="g.sub_goals"
											:id="studentId"
										/>
									</td>
								</tr>
							</template>
						</template>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
import ChildGoal from '@/components/elements/ChildGoal.vue';

export default {
	name: 'GoalListFull',
	components: { TooltipGoalDetail, ChildGoal },

	props: {
		data: {
			type: Array,
			required: true,
		},

		studentId: {
			type: Number,
			required: true,
		},
	},

	computed: {
		org() {
			return this.$store.state.profile.current_org;
		},
	},

	methods: {
		openSubGoal(goal) {
			if (goal.sub_goal_count > 0) goal.open = !goal.open;
			else return;
		},
	},
};
</script>

