
import { defineComponent, onMounted, ref, watch } from 'vue';
import { $api } from '@/services';
import { useRoute, useRouter } from 'vue-router';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { timeSinceHour } from '@/helper/formatDate';
import ViewPhoto from '@/components/common/ViewPhoto.vue';

export default defineComponent({
	components: { ViewPhoto, DaIcon },
	setup() {
		const route = useRoute();
		const router = useRouter();
		const reportDetail = ref(null as any);
		const series_ = ref([] as any);
		const seriesData = ref([]);
		const monthly_start = ref(route.query.month_start as string);
		const studentId = ref(route.params.id);
		const goalId = ref(route.params.goalId);
		const report = ref(null as any);
		const goal = ref(null as any);
		const goals = ref(null as any);
		const isLoaded = ref(false);

		async function fetchGoalAssignmentDetail() {
			try {
				const { data } = await $api.monthlyReport.assessmentReports(
					studentId.value + '',
					report.value.id,
					goalId.value + ''
				);
				reportDetail.value = data.data;
				goal.value = reportDetail.value?.assessment_report?.goal;
				goals.value = reportDetail.value?.goal;
				console.log('goal.value', goal.value);
				seriesData.value = await data.data?.assessments.map(
					(item: any) => {
						return +item.complete_percent;
					}
				);
				series_.value = [
					{
						name: 'Kết quả buổi học',
						data: seriesData.value,
					},
				];
			} catch (e) {
				console.log(e);
			}
		}

		async function fetchReport() {
			isLoaded.value = false;
			try {
				const { data } = await $api.monthlyReport.monthlyReport(
					studentId.value + '',
					monthly_start.value
				);
				if (data) {
					report.value = data.data;
					await fetchGoalAssignmentDetail();
				}
				isLoaded.value = true;
			} catch (e) {
				isLoaded.value = true;
				console.log(e);
			}
		}

		function exit() {
			router.push(
				`/report/monthly-report/${studentId.value}?month_start=${monthly_start.value}`
			);
		}

		function reason() {
			const as = reportDetail.value?.assessment_report;
			const stopped_reason =
				reportDetail.value?.assessment_report?.stopped_reason || '';
			const stopped_reason_other =
				reportDetail.value?.assessment_report?.stopped_reason_other ||
				'';
			const other =
				stopped_reason !== 'Khác' && stopped_reason !== 'other'
					? stopped_reason.toLowerCase()
					: stopped_reason_other.toLowerCase();
			return as?.next_plan !== 'Tạm dừng mục tiêu'
				? as?.next_plan
				: `${as?.next_plan} vì ${other}`;
		}

		function countMedia(g: any) {
			return g?.goal_media >= 6 ? 6 : g?.goal_media?.length;
		}

		const imageShow = ref(false);
		const photosIndex = ref(0);
		const photos = ref([]);
		const noArray = ref([]);

		function openMediaPopup(media: any, index: any) {
			imageShow.value = true;
			photos.value = media;
			photosIndex.value = index;
		}

		function haveMediaByLessonId(lessonId: any) {
			return goal.value?.goal_media?.find(
				(item: any) => item.lesson_report_id === lessonId
			);
		}

		function loadThumbnail(asset: any) {
			const imageRequest = JSON.stringify({
				bucket: asset.image_bucket,
				key: asset.video_poster_asset_key,
				edits: {
					width: 400,
					height: 400,
				},
			});
			return `${asset.image_cloudfront_url}/${btoa(imageRequest)}`;
		}

		function loadImage(asset: any) {
			const imageRequest = JSON.stringify({
				bucket: asset.image_bucket,
				key: asset.asset_key,
				edits: {
					width: 400,
					height: 400,
				},
			});
			return `${asset.image_cloudfront_url}/${btoa(imageRequest)}`;
		}

		const scrollable = ref(false);
		onMounted(() => {
			window.scrollTo(0, 0);
			setTimeout(() => {
				const feedbackBox = document.getElementById('feedback-box');
				if (feedbackBox) {
					scrollable.value =
						feedbackBox?.scrollWidth > feedbackBox?.clientWidth;
				}
			}, 3000);
		});

		watch(
			isLoaded,
			() => {
				if (isLoaded.value) {
					setTimeout(() => {
						const feedbackBox =
							document.getElementById('feedback-box');
						if (feedbackBox) {
							scrollable.value =
								feedbackBox?.scrollWidth >
								feedbackBox?.clientWidth;
						}
					}, 1000);
				}
			},
			{
				immediate: true,
			}
		);

		const lineChart = ref(null as any);

		watch(
			lineChart,
			() => {
				setTimeout(() => {
					lineChart.value?.updateOptions({
						labels: '',
					});
				}, 1000);
			},
			{ immediate: true, deep: true }
		);

		fetchReport();

		function scrollLeft() {
			const feedbackBox = document.getElementById('feedback-box');
			if (feedbackBox) {
				const position = feedbackBox.scrollLeft;
				feedbackBox.scrollTo({
					left: position - 800,
					behavior: 'smooth',
				});
			}
		}
		function scrollRight() {
			const feedbackBox = document.getElementById('feedback-box');
			if (feedbackBox) {
				const position = feedbackBox.scrollLeft;
				feedbackBox.scrollTo({
					left: position + 800,
					behavior: 'smooth',
				});
			}
		}

		function getStatus(state: any) {
			switch (state) {
				case 'completed':
					return `<div class=" flex items-center gap-2">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5ZM12.0303 5.46967C11.7374 5.17678 11.2625 5.17678 10.9697 5.46967C10.9626 5.47674 10.9559 5.48424 10.9498 5.4921L7.47739 9.91674L5.38387 7.82322C5.09097 7.53033 4.6161 7.53033 4.32321 7.82322C4.03031 8.11612 4.03031 8.59099 4.32321 8.88388L6.96965 11.5303C7.26255 11.8232 7.73742 11.8232 8.03031 11.5303C8.03684 11.5238 8.04299 11.5169 8.04875 11.5097L12.041 6.51947C12.3232 6.22582 12.3196 5.75897 12.0303 5.46967Z" fill="#2AA797"/>
                  </svg>
                  <span class="text-neutral-700"> Hoàn thành </span>
                  </div>`;
				case 'stopped':
					return `<div class=" flex items-center gap-2">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM12.1667 8.49677C12.1653 8.13006 11.942 7.83333 11.6667 7.83333H4.66667C4.39052 7.83333 4.16667 8.13181 4.16667 8.5C4.16667 8.86819 4.39052 9.16667 4.66667 9.16667H11.6667C11.942 9.16667 12.1653 8.86994 12.1667 8.50323C12.1667 8.50216 12.1667 8.50108 12.1667 8.5C12.1667 8.49892 12.1667 8.49784 12.1667 8.49677Z" fill="#E37056"/>
                  </svg>
                  <span class="text-neutral-700"> Tạm dừng </span>
                  </div>`;
				case 'report':
					return `<div class=" flex items-center gap-2">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5338 7H15.4662C15.6782 7 15.794 7.24721 15.6583 7.41005L13.6921 9.76953C13.5921 9.88947 13.4079 9.88947 13.3079 9.76953L11.3417 7.41005C11.206 7.24721 11.3218 7 11.5338 7Z" fill="#94A3B8"/>
                  <path d="M0.533761 9H4.46624C4.6782 9 4.79399 8.75279 4.6583 8.58995L2.69206 6.23047C2.59211 6.11053 2.40789 6.11053 2.30795 6.23047L0.341706 8.58995C0.206013 8.75279 0.321802 9 0.533761 9Z" fill="#94A3B8"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 3C6.44751 3 5.06057 3.70697 4.14269 4.81839C3.96685 5.03131 3.65169 5.06137 3.43877 4.88552C3.22586 4.70968 3.1958 4.39453 3.37164 4.18161C4.47134 2.85003 6.13662 2 8 2C10.9417 2 13.388 4.1165 13.9011 6.90967C13.9066 6.9397 13.9119 6.96981 13.917 7H12.9001C12.4371 4.71778 10.4186 3 8 3ZM3.09992 9C3.56293 11.2822 5.58137 13 8 13C9.55249 13 10.9394 12.293 11.8573 11.1816C12.0332 10.9687 12.3483 10.9386 12.5612 11.1145C12.7741 11.2903 12.8042 11.6055 12.6284 11.8184C11.5287 13.15 9.86339 14 8 14C5.0583 14 2.61198 11.8835 2.09892 9.09033C2.0934 9.0603 2.08811 9.03019 2.08304 9H3.09992Z" fill="#94A3B8"/>
                  </svg>
                  <span class="text-neutral-700"> MT lặp lại </span>
                  </div>`;
				case 'library':
					return `
                  <div class="text-neutral-700">Ngân hàng MT</div>
                  `;
				case 'learning':
					return `
                  <div class="text-neutral-700">Đang học</div>
                  `;
				default:
					return '';
			}
		}

		return {
			goal,
			goals,
			getStatus,
			scrollable,
			timeSinceHour,
			scrollLeft,
			scrollRight,
			noArray,
			imageShow,
			countMedia,
			openMediaPopup,
			haveMediaByLessonId,
			loadThumbnail,
			loadImage,
			photos,
			photosIndex,
			report,
			reason,
			reportDetail,
			series_,
			chartOptions_: {
				colors: ['#2AA797'],
				chart: {
					type: 'line',
					zoom: {
						enabled: false,
					},
					animations: {
						enabled: false,
					},
				},
				stroke: {
					width: 3,
				},
				yaxis: {
					labels: {
						show: false,
					},
					max: 100,
					min: 0,
					tickAmount: 5,
				},
				grid: {
					show: true,
					borderColor: '#F1F5F9',
				},
				markers: {
					size: 8,
					strokeColors: '#fff',
					strokeWidth: 2,
				},
			},
			studentId,
			monthly_start,
			exit,
		};
	},
});
